import React from "react";

import LayoutBlog from "../components/LayoutBlog";
import Footer from "../components/Footer";
import Header from "../components/Header";
import LogoFull from "../components/LogoFull";
import EmailForm from "../components/EmailForm";
import EmailFormInPage from "../components/EmailFormInPage";
import BackToHomeArrow from "../components/BackToHomeArrow";
import ShareLinks from "../components/ShareLinks";
import PopUpOnExitEmailForm from '../components/PopUpOnExitEmailForm';

const BlogMain = () => (
  <LayoutBlog
    title={"Crafted #005 - Devin Singley, Fortnight Brewing"}
    blogUrl={"https://joincrafted.com/005-devin-singley-fortnight-brewing"}
    description={
      "Devin Singley, brewmaster at Fortnight Brewing, takes us beyond beer while also sharing a thing or two about how to make it well."
    }
    imageUrl={
      "https://www.joincrafted.com/assets/images/adam-wilson-ANK5zq-g_-g-unsplash_resized.jpg"
    }>
    <Header />
    <div>
      <main className="blog-main" role="main">
        <div className="blog-left">
          <ShareLinks
            twitterLink={
              "https://twitter.com/intent/tweet?text=Devin%20Singley,%20brewmaster%20at%20Fortnight%20Brewing,%20takes%20us%20beyond%20beer%20while%20also%20sharing%20a%20thing%20or%20two%20about%20how%20to%20make%20it%20well.%0A%0Ahttps%3A//joincrafted.com/005-devin-singley-fortnight-brewing"
            }
            facebookLink={
              "https://www.facebook.com/sharer/sharer.php?u=https%3A//joincrafted.com/005-devin-singley-fortnight-brewing"
            }
          />
        </div>
        <div className="slim-container">
          <div className="mt-4 mx-2 d-flex justify-content-between">
            <div>
              <BackToHomeArrow />
            </div>
            <div
              style={{
                color: "rgba(40, 50, 78, 0.5)",
                fontSize: "16px",
                lineHeight: "1.5"
              }}
            >
              <p>October 19, 2020</p>
            </div>
          </div>
          <div className="100vw d-flex justify-content-center mt-4">
            <LogoFull></LogoFull>
          </div>
          <table role="presentation" className="document" align="center">
        <tbody>
          <tr>
            <td valign="top">
              <table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width={630} className="container">
                <tbody>
                  <tr className="inner-body">
                    <td>
                      <table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                        <tbody>
                          <tr bgcolor="#ffffff" style={{backgroundColor: '#ffffff'}}>
                            <td className="container">
                              <table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width="100%">
                                <tbody>
                                  <tr>
                                    <td style={{padding: '5px 5px'}}>
                                      <table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{textAlign: 'center', marginBottom: '2em', color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <h1 style={{color: "rgba(40, 50, 78, 0.5)", fontWeight: '400', fontSize: '16px', lineHeight: '1.5'}}><span style={{display: 'none'}}>Crafted </span>Edition &#35;005 🍻 - Devin Singley, Fortnight Brewing</h1>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>Is craft beer something that connects people? Is it a catalyst of bonds and a creator of relationships that otherwise wouldn't exist? I think so.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '16px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Devin Singley</strong>, brewmaster at <a href="https://www.fortnightbrewing.com/" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Fortnight Brewing</a> gives us a glimpse into a craft beer industry that is more than beer. Sure, there's some beer involved, some planning &amp; logistics, some cleaning equipment. But that's just the surface.
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>Brewing has always been about more than beer. There's the somewhat mundane behind-the-scenes daily work and then there's the sitting-around-a-table while enjoying the end product. Two sides of the same coin. When you do it right, it brings people together and builds communities.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p>Read on as Devin shares about his role, the industry, and beyond. You'll find out about:
                                                </p>
                                                <ul>
                                                  <li>A lost librarian that became a brewmaster</li>
                                                  <li>How "Beer is small" and the craft community is close-knit</li>
                                                  <li>A few ways Devin has witnessed beer being used for the common good</li>
                                                </ul>
                                                <p style={{paddingTop: '16px'}}>Let's get to it 🚀</p>
                                              </div></td></tr></tbody></table>
                                              <table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}>
                                              <div className="text-block" style={{lineHeight: '150%'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}> </span></span>
                                              <EmailFormInPage />
                                              <div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Where do you brew and what's your role? How'd you get into brewing? What do you like most about it? </strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>On paper I am the Brewmaster at Fortnight Brewing in Cary, NC. Realistically my job title is Devin. I do scheduling (for our beers and for contract brews), I make new beer recipes and a lot of maintenance. I end up spending a lot of time cleaning and making sure all the parts are working.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Years ago I was a librarian. I loved beer and the history of beer. When the economy crashed in 2008 and led to budget cuts in 2009, I was laid off. The first day I was unemployed, my house mates and I went to a brewery taproom. It dawned on me that real people work in breweries.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  To make a short story long, I set out the next week to find a job in a brewery. I went door to door walking into breweries asking for a job application. That Friday I took a tour at my favorite brewery in North Carolina. I walked off the tour and told the lady in the office that I was there for an interview. She then refunded my money for the tour and let me use her computer to print out my resume.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  I couldn’t believe it was working.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Five minutes later, I was sitting in a conference room with the owner of the brewery. He was an older man and asked me if I knew anything about Facebook. Remember, 2009. I showed him I had an app on my phone that let me use Facebook remotely. He decided he needed to hire me to do social media marketing.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  So I had a marketing role that included being a draft tech, doing deliveries, and selling beer. That led to working on the bottling line, which led to filling kegs, which led to brewing beer.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  11 years later, I have people who work for me who hope to work their way up like I did.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  I think what I like most is that I love working with my hands. I know how to look at a brewery and see what needs to be done. When I worked at the library, it was like I was lost every day. I sat at a computer and I had no clue what to do.
                                                </p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}} role="presentation">
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/nocrawl/121406075_191776299056028_4592205469947458208_n.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: '0px', outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} role="presentation"><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top">
                                              <div style={{textAlign: 'center', color: '#28324E', fontSize: '14px', lineHeight: '1.5', opacity: '0.5', wordWrap: 'break-word'}}>
                                                Image source: https://www.instagram.com/fortnightbeer/</div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What's one part of your job that people who are outside the industry wouldn't know that you do?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>So many things! Honestly, actually brewing beer is the least of what I do.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Recipes are the easy part. I know what grains add color, character, texture. I know what yeast strains I need to get the flavors I want, and I understand how to use hops in the right way to achieve my goals. My job does not look like a Sam Adams commercial.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Planning is the biggest part of my job. We order grains by the pallet, so I have to plan for what we need weeks down the line, order the ingredients and account for time in shipping.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Hops bought on the spot market can take weeks to arrive and contracting hops takes more projecting and planning.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Unlike home brewing, I have to look at the market for my ingredients and plan out if a beer is going to be made more that once, how am I accounting for the supply chain. A home brewer might have no trouble buying Galaxy hops at the store by the ounce. But I have to know crop yields and market demand to plan out if I am setting myself up for failure to make a beer with hops I can’t buy a second time.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  I have to plan for time to get labels designed and printed. How to use a role of labels efficiently. I have to plan for tank residency on a brew and my keg requirements. </p>
                                              <EmailFormInPage />
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What is the greatest life lesson that brewing beer has ever taught you?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>Beer is small.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  I was told that early in my beer career. It has only gotten truer as beer has grown. There are over 50 breweries in my immediate area. I know most of the brewers in the region. We raise our kids together. We text each other memes and jokes. We help each other. We network and we try to build each other up.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  If you make bad beer, if your standards aren’t there, if you’re a jerk and you don’t help people, everyone will know. Word travels fast and what gets said around the campfire can greatly impact your employability, your taproom sales, your ability to attract good people around you.
                                                </p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>What is the one beer that you are most proud of brewing? How was it born and why is it special to you?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>I made a beer called <a href="https://untappd.com/b/bombshell-beer-company-lady-in-red-amber-ale/1856045/photos" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">Lady in Red</a> at Bombshell Beer Company. Bombshell was my fourth brewery and it had 4 malts (each representing a different brewery along my path) and 4 hop additions.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  It was tasty and won awards in every competition.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  It was a beer for me. I love amber beer, and this amber hit all the notes I wanted.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  In 2019 I won a <a href="https://www.greatamericanbeerfestival.com/" style={{color: '#6821AB', textDecoration: 'underline'}} target="_blank" rel="noreferrer">GABF</a> medal for the barrel aged version. I created the barrel program for Bombshell and worked hard to get Lady in Red just right for the wood aging.
                                                </p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}} role="presentation">
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/nocrawl/bombshell_brewing_lady_in_red.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} role="presentation"><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                The beautiful <em>Lady in Red</em>
                                              </div>
                                              <div style={{textAlign: 'center', color: '#28324E', fontSize: '14px', lineHeight: '1.5', opacity: '0.5', wordWrap: 'break-word'}}>
                                                Image source: https://www.facebook.com/BombshellBeerCompany</div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#3D405B', fontSize: '20px', lineHeight: '1.5', margin: '15px 0', backgroundColor: '#F3F3F7', padding: '8px', borderRadius: '4px'}}>
                                                <strong>Do you think craft beer has a special role to play in communities? What is this role, in your opinion? In other words, what is the purpose of craft beer, beyond the glass?</strong>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>People who gather and drink beer together have a different relationship than just any set of people.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Over the last 11 years, I’ve seen a wedding in a brewery’s parking lot; a fundraiser for a woman who’s house burned down; a man literally take the shirt off his back to give to a coat drive; a school use a taproom for teacher training when the building couldn’t be occupied; an HOA meeting; a sport wheelchair be gifted to a handicapped man who wanted to more active; bands play in front of an audience for the first time; firefighters being fed for free after saving an adjacent building.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  During the height of the pandemic I saw breweries help each other and organize parking lot sales to make sure we were all still selling beer.
                                                </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Brewers often help each other. We lend sacks of grain, pitches of yeast and boxes of hops. Tools, equipment, expertise - we share a lot. </p>
                                                <p style={{paddingBottom: '16px'}}>
                                                  Brewery taprooms have become the modern meeting place and message board. </p>
                                              </div></td></tr></tbody></table><table border={0} className="container" align="center" cellPadding={0} cellSpacing={0} width="100%" style={{marginBottom: '5px', marginTop: '5px'}} role="presentation">
                                        <tbody>
                                          <tr>
                                            <td align="center" className="hero" style={{borderCollapse: 'collapse', borderSpacing: 0, margin: 0, padding: 0}} valign="top">
                                              <img src="/assets/images/singley/devin_singley.jpg" width={620} style={{width: '100%', maxWidth: '100%', objectFit: 'cover', padding: 0, outline: 'none', border: 'none', display: 'block'}} />
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} role="presentation"><tbody><tr><td style={{padding: '8px 0px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5', width: '100%', paddingBottom: '32px', textAlign: 'center'}}>
                                                Devin, enjoying that thing that brings us together
                                              </div>
                                              <EmailFormInPage />
                                            </td></tr></tbody></table><table role="presentation" cellSpacing={0} cellPadding={0} border={0} align="center" width="100%"><tbody><tr><td className="container" style={{paddingTop: '15px', paddingBottom: '15px'}}><div className="text-block" style={{lineHeight: '150%'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}> </span></span><div style={{textAlign: 'center'}}><span className="fr-unprocessed" style={{color: 'rgb(40, 50, 78)'}}><span style={{color: 'rgb(40, 50, 78)'}}>***</span><br /></span></div></div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>Here is a guy that get's it.</p>
                                                <p style={{paddingBottom: '8px'}}>A guy that knows how to make good beer, who's also proven he can successfully run the business &amp; logistical side of a brewing company. But the icing on the cake is that he sees beyond beer.</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '8px'}}>Devin mentioned that <strong>people who gather and drink beer together have a different relationship than just any set of people</strong>. My experience with craft beer confirms this. What about you? If you've visited almost any taproom across the world you probably know what I mean. You get a warm welcome, a good conversation with a nice beverage. There's something about these people...</p>
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%'}} role="presentation"><tbody><tr><td style={{padding: 0, borderCollapse: 'collapse', borderSpacing: 0, margin: 0}} valign="top"><div style={{color: '#4cb14c', fontSize: '16px', lineHeight: '1.5', backgroundColor: '#F3F9F0', padding: '8px', borderRadius: '4px', margin: '15px 0'}}>
                                                I want to say a huge thank you to <strong>Devin</strong> for sharing his time and thoughts with us. This edition would never have happened without his generosity and his willingness to lend a helping hand.
                                              </div></td></tr></tbody></table><table width="100%" style={{tableLayout: 'fixed', width: '100%', marginBottom: '16px'}} role="presentation"><tbody><tr><td style={{padding: '0px 0px 8px', borderCollapse: 'collapse', borderSpacing: '0px', margin: '0px'}} valign="top"><div style={{color: '#28324E', fontSize: '16px', lineHeight: '1.5'}}>
                                                <p style={{paddingBottom: '16px'}}>If you're reading this, you're probably part of that craft beer community, full of people who support each other and help their communities in myriad ways - keep it up!</p>
                                                <p style={{paddingBottom: '16px'}}>Take care out there, make time for stuff that matters. Until next time 👋</p>
                                                <p style={{paddingBottom: '16px'}}>Cheers! 🍻</p>
                                                <p style={{paddingBottom: '16px'}}>- Jake</p></div></td></tr></tbody></table></td></tr></tbody></table></td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
          <hr />
          <div className="spacer-div"></div>
        </div>
        <div className="blog-right"></div>
      </main>
    </div>
    <div id="overlay">
      <div id="loader-object"></div>
    </div>
    <Footer />
  </LayoutBlog>
);

export default BlogMain;
